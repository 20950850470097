import axios from 'config/axios';
import { useQuery } from '@tanstack/react-query';
import { environment } from 'config/environment';
import { DaycareDto } from 'api/dto/daycare';

export const useGetDaycareDays = () => {
  return useQuery<DaycareDto[]>(['daycare-days'], async () => {
    const { data } = await fetchDaycareDays();
    return data || [];
  });
};

// TODO
// const fetchDaycareDays = () =>
//   new Promise<{ data: { items: DaycareDto[] } }>((resolve, reject) => {
//     resolve({ data: { items: [] } });
//   });
const fetchDaycareDays = async () => await axios.get<DaycareDto[]>(environment.restApi.resourceUrl.daycare + '/days');
