/* eslint-disable @typescript-eslint/naming-convention */
import { DaycareSteps, EnglishLevel, Gender, LinkWithChildren, ParentTitle, Steps } from './types';
import Joi from 'joi';
import { showNotification } from '@mantine/notifications';
import { tlds } from '@hapi/tlds';
import { DateTime } from 'utils/datetime';

// https://github.com/validatorjs/validator.js/blob/master/src/lib/isMobilePhone.js
const phoneRegex = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

const phoneMessages = {
  'string.pattern.base': 'Le numéro de téléphone est invalide',
  'string.empty': 'Le champs est requis',
  'any.required': 'Le champs est requis',
};

const parentValidation = Joi.object({
  title: Joi.string()
    .valid(...Object.values(ParentTitle))
    .required()
    .messages({
      'any.only': 'Veuillez choisir une des options si dessus',
    }),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  address: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: tlds }, minDomainSegments: 2 })
    .message('Le courriel est invalide')
    .required(),
  cellphone: Joi.string().required().regex(phoneRegex).messages(phoneMessages),
  phone: Joi.string().regex(phoneRegex).messages(phoneMessages).optional().allow(''),
});

const childrenValidation = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),

  englishLevel: Joi.string()
    .required()
    .valid(...Object.values(EnglishLevel))
    .messages({
      'any.only': 'Veuillez choisir une des options si dessus',
    }),
  gender: Joi.string()
    .required()
    .valid(...Object.values(Gender))
    .messages({
      'any.only': 'Veuillez choisir une des options si dessus',
    }),

  birthDate: Joi.date().required(),

  healthInsuranceNumber: Joi.string()
    .custom((value, helper) => {
      if (!/^[A-Za-z]{4}[0-9]{8}$/.test(value)) {
        // eslint-disable-next-line quotes
        return helper.message('Le format doit être de la forme AAAA12345678' as any);
      }

      // we need to get the value of the birthDate field
      const birthDate = helper.state.ancestors[0].birthDate;
      const birthYear = new Date(birthDate).getFullYear();

      const healthInsuranceDate = getBirthDateByHealthInsuranceNumber(value);

      if (!healthInsuranceDate) {
        // eslint-disable-next-line quotes
        return helper.message('Le format doit être de la forme AAAA12345678' as any);
      }

      // if the health insurance number year is not equal to the birth year
      if (birthYear !== healthInsuranceDate.date.year()) {
        // eslint-disable-next-line quotes
        return helper.message("Ce numéro d'assurance maladie ne concorde pas avec la date de naissance de l'enfant." as any);
      }

      return value;
    })
    .required(),
  healthInsuranceExpiryDate: Joi.date().required(),

  hasAllergies: Joi.boolean().required(),
  allergiesExplanations: Joi.string().when('hasAllergies', { is: true, then: Joi.string().required() }),

  takesDrugs: Joi.boolean().required(),
  drugsExplanations: Joi.string().when('takesDrugs', { is: true, then: Joi.string().required() }),

  hasRestrictedConcentrations: Joi.boolean().required(),
  restrictedConcentrationsExplanations: Joi.string().when('hasRestrictedConcentrations', { is: true, then: Joi.string().required() }),

  hasSpecificDevelopmentPlan: Joi.boolean().required(),
  specificDevelopmentPlanDetails: Joi.string().when('hasSpecificDevelopmentPlan', { is: true, then: Joi.string().required() }),
});

const termsAndConditionsValidation = Joi.object({
  needsToCommunicateModificationsHealth: Joi.boolean().invalid(false).required(),
  isAuthorizedToAdministerFirstCare: Joi.boolean().invalid(false).required(),
  hasToCollaborateWithManagement: Joi.boolean().invalid(false).required(),
  hasReadAndAcceptedTermsAndConditions: Joi.boolean().invalid(false).required(),
  allInfosAreTruthful: Joi.boolean().invalid(false).required(),
});

const authorizedPeopleValidation = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  link: Joi.string()
    .required()
    .valid(...Object.values(LinkWithChildren)),
});

const unauthorizedPeopleValidation = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
});

const legalAuthorizationsValidation = Joi.object({
  canAdministerDrugs: Joi.boolean().required(),
  hasChildcare: Joi.boolean().required(),
  childcareTime: Joi.string().when('hasChildcare', { is: true, then: Joi.string().required() }),
  canReturnHomeAlone: Joi.boolean().required(),
  authorizedPeople: Joi.array().required().items(authorizedPeopleValidation),
  unauthorizedPeople: Joi.array().required().items(unauthorizedPeopleValidation),
  isAuthorizedToSharePhotos: Joi.boolean().required(),
});

const emergencyContactValidation = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  link: Joi.string()
    .required()
    .valid(...Object.values(LinkWithChildren))
    .messages({
      'any.only': 'Veuillez choisir une options',
    }),
  cellphone: Joi.string().required().regex(phoneRegex).messages(phoneMessages),
  phone: Joi.string().regex(phoneRegex).messages(phoneMessages).optional().allow(''),
});

const scheduleValidation = Joi.object({
  child: Joi.object().required(),
  weeks: Joi.object()
    .required()
    .pattern(Joi.string(), Joi.object().required().keys({ days: Joi.array().required(), concentrationId: Joi.string() }))
    .min(1),
});

const taxCreditValidation = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  socialNumber: Joi.string().required(),

  email: Joi.string().email({ tlds: false }).message('Le courriel est invalide').required(),
  address: Joi.string().required(),
  city: Joi.string().required(),
  province: Joi.string().required(),
  postalCode: Joi.string().required(),
  cellphone: Joi.string().required().regex(phoneRegex).messages(phoneMessages),
  phone: Joi.string().regex(phoneRegex).messages(phoneMessages).optional().allow(''),
});

export function validateStep(activeStep: Steps, values: any) {
  let schema: Joi.ObjectSchema<any>;
  switch (activeStep) {
    case Steps.infos: {
      schema = Joi.object({
        camp: Joi.string().required(),
        family: Joi.object({
          parents: Joi.array().required().min(1).items(parentValidation),
          children: Joi.array().required().min(1).items(childrenValidation),
          termsAndConditions: termsAndConditionsValidation.required(),
          legalAuthorizations: legalAuthorizationsValidation.required(),
          emergencyContacts: Joi.array().required().min(2).items(emergencyContactValidation),
        }),
      });
      break;
    }

    case Steps.schedule: {
      schema = Joi.object({
        schedule: Joi.object({
          items: Joi.object().required().pattern(Joi.string(), scheduleValidation).min(1),
        }),
      });
      break;
    }

    case Steps.tax: {
      schema = Joi.object({
        taxCreditInfos: Joi.array().min(1).items(taxCreditValidation),
      });
      break;
    }

    case Steps.menu: {
      schema = Joi.object({
        menu: Joi.array(),
      });
      break;
    }

    default:
      return {};
  }

  const { error } = schema.validate(values, { abortEarly: false, allowUnknown: true });

  if (error) {
    const errorObject = error.details.reduce((acc: any, curr) => {
      const path = curr.path.join('.');

      if (curr.message.includes('required') || curr.message.includes('empty')) {
        acc[path] = 'Le champs est requis';
      } else {
        acc[path] = curr.message;
      }

      return acc;
    }, {});

    const errorKeys = Object.keys(errorObject);

    errorKeys.forEach((key) => {
      if (key.includes('schedule.items') && key.includes('weeks')) {
        showNotification({
          title: 'Erreur',
          message: 'Veuillez choisir au moins une plage horaire pour chaque enfant',
          color: 'red',
        });
        return errorObject;
      }
    });

    return errorObject;
  }

  return {};
}

const termsAndConditionsDaycareValidation = Joi.object({
  isAuthorizedToAdministerFirstCare: Joi.boolean().invalid(false).required(),
  hasToCollaborateWithManagement: Joi.boolean().invalid(false).required(),
  hasReadAndAcceptedTermsAndConditions: Joi.boolean().invalid(false).required(),
  allInfosAreTruthful: Joi.boolean().invalid(false).required(),
});

const legalAuthorizationsDaycareValidation = Joi.object({
  canAdministerDrugs: Joi.boolean().required(),
  canReturnHomeAlone: Joi.boolean().required(),
  authorizedPeople: Joi.array().required().items(authorizedPeopleValidation),
  unauthorizedPeople: Joi.array().required().items(unauthorizedPeopleValidation),
});

export function validateDaycareStep(activeStep: DaycareSteps, values: any) {
  let schema: Joi.ObjectSchema<any>;
  switch (activeStep) {
    case DaycareSteps.infos: {
      schema = Joi.object({
        family: Joi.object({
          parents: Joi.array().required().min(1).items(parentValidation),
          children: Joi.array().required().min(1).items(childrenValidation),
          termsAndConditions: termsAndConditionsDaycareValidation.required(),
          legalAuthorizations: legalAuthorizationsDaycareValidation.required(),
          emergencyContacts: Joi.array().required().min(2).items(emergencyContactValidation),
        }),
      });
      break;
    }

    case DaycareSteps.tax: {
      schema = Joi.object({
        taxCreditInfos: Joi.array().min(1).items(taxCreditValidation),
      });
      break;
    }
  }

  const { error } = schema.validate(values, { abortEarly: false, allowUnknown: true });

  if (error) {
    const errorObject = error.details.reduce((acc: any, curr) => {
      const path = curr.path.join('.');

      if (curr.message.includes('required') || curr.message.includes('empty')) {
        acc[path] = 'Le champs est requis';
      } else {
        acc[path] = curr.message;
      }

      return acc;
    }, {});
    return errorObject;
  }
  return {};
}

export function getBirthDateByHealthInsuranceNumber(healthString: string): DateTime | null {
  const healthInsuranceNumber = healthString.split(' ').join('');

  const healthInsuranceNumberRegex = /^[A-Za-z]{4}\d{8}$/;
  if (!healthInsuranceNumberRegex.test(healthInsuranceNumber)) {
    return null;
  }

  const birthYearLastTwoDigits = healthInsuranceNumber.substring(4, 6);
  let birthMonth = parseInt(healthInsuranceNumber.substring(6, 8), 10);
  const birthDay = parseInt(healthInsuranceNumber.substring(8, 10), 10);

  // Subtracting 50 from birth month if it is greater than 50
  if (birthMonth > 50) {
    birthMonth -= 50;
  }

  // Assuming birth year is between 1950 and 2050
  const birthYearPrefix = parseInt(birthYearLastTwoDigits, 10) < 50 ? 2000 : 1900;
  const birthYear = birthYearPrefix + parseInt(birthYearLastTwoDigits, 10);
  const birthDate = new Date(birthYear, birthMonth - 1, birthDay); // Month is zero-based in JavaScript Date

  return DateTime.fromDate(birthDate);
}
