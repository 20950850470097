import { SimpleGrid, Box, createStyles, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

const useFooterStyles = createStyles(() => ({
  container: {
    width: '100%',
    minHeight: 320,
    backgroundColor: '#2c394c',
    display: 'flex',
    justifyContent: 'center',
  },
  section: {
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    fontFamily: 'Mulish',
    color: '#fff',
    fontWeight: 700,
    lineHeight: '1.4375rem',
    letterSpacing: '.0313rem',
    fontSize: '1.125rem',
    marginBottom: '1.5625rem',
  },
  text: {
    color: '#fff',
    fontFamily: 'Rum Raisin',
    fontWeight: 400,
    fontSize: '.9375rem',
    lineHeight: '1.5rem',
    marginBottom: '.75rem',
  },
}));

export default function Footer() {
  const { t } = useTranslation();
  const { classes } = useFooterStyles();
  return (
    <Box pt={40} className={classes.container}>
      <SimpleGrid
        w="90%"
        sx={{}}
        cols={4}
        breakpoints={[
          { maxWidth: 2000, cols: 3, spacing: 32 },
          { maxWidth: 749, cols: 1, spacing: 'xs' },
        ]}
      >
        <Box className={classes.section}>
          <Text className={classes.title}>{t('Footer.Contact')}</Text>
          <Text className={classes.text}>{t('Footer.EnglishSummerCamp')}</Text>
          <Text className={classes.text}>{t('Footer.AddressVicto')}</Text>
          <Text className={classes.text}>{t('Footer.AddressDrummond')}</Text>
        </Box>
        <Box className={classes.section}>
          <Text className={classes.title}>{t('Footer.Phone')}</Text>
          <Text className={classes.text}>{t('Footer.OffSeason')}</Text>
          <Text className={classes.text}>{t('Footer.Seasonal')}</Text>
          <Text className={classes.text}>{t('Footer.PhoneNumberVicto')}</Text>
          <Text className={classes.text}>{t('Footer.PhoneNumberDrummond')}</Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
}
