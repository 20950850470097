import { Box, Button, Text } from '@mantine/core';
import { Band } from 'component/band/band';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function Closed() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // TODO: Eventually get the nearest camp registration start date
  const registrationStartDate = dayjs(`${dayjs().year()}-03-01`);

  return (
    <>
      <Band text={t('Inscription.Closed')} />

      <Box ml="10%" mr="10%" mt={40} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexFlow: 'column' }}>
        <Text
          size="xl"
          mb={30}
          sx={{
            fontFamily: 'Rum Raisin',
          }}
        >
          {t('Common.Oops')}
        </Text>
        <Text
          size="md"
          sx={{
            fontFamily: 'Rum Raisin',
          }}
        >
          {t('Common.NoActiveCamp', { replace: { registrationStartDate: registrationStartDate.format('Do MMMM YYYY') } })}
        </Text>

        <Button onClick={() => navigate('/registration')} mt={60} mb={60} size="lg">
          {t('Common.Retry')}
        </Button>
      </Box>
    </>
  );
}
