import { CampDto, CampStatus } from 'api/dto';
import { Concentration } from './concentration';
import { Product } from './product';
import { MenuItem } from './menu-item';
import dayjs from 'dayjs';

export class Camp {
  id: string;
  nameEn: string;
  nameFr: string;

  descriptionEn?: string;
  descriptionFr?: string;

  products: Product[];
  menu: MenuItem[];

  concentrations: Concentration[];
  startDate: string;
  endDate: string;
  registrationStartDate: string;
  closedOn: string[];
  status: CampStatus;
  invoiceDates: string[];
  menuOverrideOptions: string[];
  waitingListUrl?: string;
  registrationFees: number;
  managementFees: number;

  constructor(camp: CampDto) {
    this.id = camp.id;
    this.nameEn = camp.nameEn;
    this.nameFr = camp.nameFr;
    this.descriptionEn = camp.descriptionEn;
    this.descriptionFr = camp.descriptionFr;
    this.products = camp.products.map((product) => new Product(product));
    this.menu = camp.menu.map((menuItem) => new MenuItem(menuItem));
    this.concentrations = camp.concentrations.map((concentration) => new Concentration(concentration));
    this.startDate = camp.startDate;
    this.endDate = camp.endDate;
    this.registrationStartDate = camp.registrationStartDate;
    this.closedOn = camp.closedOn;
    this.status = camp.status;
    this.invoiceDates = camp.invoiceDates;
    this.menuOverrideOptions = camp.menuOverrideOptions;
    this.waitingListUrl = camp.waitingListUrl;
    this.registrationFees = camp.registrationFees;
    this.managementFees = camp.managementFees;
  }

  get isInactive(): boolean {
    return this.status === CampStatus.INACTIVE || this.isCampClosed || !this.isRegistrationOpened;
  }

  get isRegistrationOpened(): boolean {
    return dayjs().isAfter(this.registrationStartDate);
  }

  get isCampClosed(): boolean {
    return dayjs().isAfter(this.endDate);
  }
}
