import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import { AppLayout } from 'layout/app.layout';
import { NewRegistration } from 'pages/new-registration';
import Explanations from 'pages/explanations';
import Closed from 'pages/closed';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import Success from 'pages/success';
import TermsAndConditions from 'pages/terms-and-conditions';
import Login from 'pages/admin/auth/login';
import { ReactNode } from 'react';
import { useAuth } from 'hooks/useAuth';
import { SchoolDaycareExplanations } from 'pages/daycare/daycare-explanations';
import { NewDaycareRegistration } from 'pages/daycare/new-daycare-registration';
import DayCareSuccess from 'pages/daycare/daycare-success';

export function App() {
  useGoogleAnalytics();
  // const { appIsHydrated } = useAuth();

  // if (!appIsHydrated) {
  //   return (
  //     <Group
  //       data-testid="loader"
  //       sx={() => ({
  //         height: '100vh',
  //         justifyContent: 'center',
  //       })}
  //     >
  //       <Center>
  //         <Loader size="xl" />
  //       </Center>
  //     </Group>
  //   );
  // }

  return (
    <Routes>
      <Route element={<AppLayout />}>
        {/* <Route path="session">
          <Route path="login" element={<Login />} />
          <Route index element={<Navigate to="/session/login" />} />
          <Route path="*" element={<Navigate to="/session/login" />} />
        </Route> */}
        <Route path="daycare-registration">
          <Route index element={<SchoolDaycareExplanations />} />
          <Route path="process" element={<NewDaycareRegistration />} />
          <Route path="success" element={<DayCareSuccess />} />
        </Route>
        <Route path="registration">
          <Route index element={<Explanations />} />
          <Route path="process" element={<NewRegistration />} />
          <Route path="success" element={<Success />} />
          <Route path="closed" element={<Closed />} />
        </Route>
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />

        {/* <Route path="admin">
          <Route
            path="registrations"
            element={
              <RequireAuth>
                <Registrations />
              </RequireAuth>
            }
          />
          <Route
            path="registrations/:familyId/:registrationId"
            element={
              <RequireAuth>
                <Registration />
              </RequireAuth>
            }
          />
          <Route index element={<Navigate to="/admin/registrations" />} />
          <Route path="*" element={<Navigate to="/admin/registrations" />} />
        </Route> */}
        <Route path="*" element={<Navigate to="/registration" />} />
      </Route>
    </Routes>
  );
}

// function RequireAuth({ children }: { children: ReactNode | ReactNode[] }) {
//   const { isAuthenticated, appIsHydrated, user } = useAuth();
//   const location = useLocation();

//   if (!isAuthenticated && appIsHydrated) {
//     return <Navigate to={'/session/login'} state={{ from: location }} replace />;
//   }

//   return <>{children}</>;
// }
