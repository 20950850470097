/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, LoadingOverlay, Text } from '@mantine/core';
import { useGetCamps } from 'api/queries';
import { Band } from 'component/band/band';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

dayjs.locale('fr');
dayjs.extend(advancedFormat);

export default function Explanations() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: camps, isLoading } = useGetCamps();
  const [overlayVisible, setOverlayVisible] = useState(true);
  const [params] = useSearchParams();

  // TODO: Eventually get the nearest camp registration start date
  const registrationStartDate = dayjs(`${dayjs().year()}-03-01`);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setOverlayVisible(false);

    if (camps && camps.length === 0) {
      navigate('/registration/closed');
    }

    if (camps && !params.get('bypass') && camps.every((c) => c.isInactive)) {
      navigate('/registration/closed');
    }
  }, [camps, isLoading, navigate, params]);

  return (
    <>
      <Band text={t('Inscription.AcademyInscriptions')} />
      <LoadingOverlay visible={overlayVisible} />
      <Box ml="10%" mr="10%" mt={40} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexFlow: 'column' }}>
        <Text mb={20} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 28 }}>
          {t('Inscription.Opening', { replace: { year: dayjs().year().toString(), fullDate: registrationStartDate.format('dddd [le] Do MMMM') } })}
        </Text>
        <Text mt={20} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 18 }}>
          {t('Inscription.HowTo')}
        </Text>
        <Button onClick={() => navigate('/registration/process')} mt={60} mb={60} size="lg" color="red">
          {t('Inscription.Start')}
        </Button>
      </Box>
    </>
  );
}
