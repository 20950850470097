import axios from 'config/axios';
import { useQuery } from '@tanstack/react-query';
import { environment } from 'config/environment';
import { CampDto } from '../dto/camp';
import { Camp } from 'api/models/camp';
import { useCallback } from 'react';

export const useGetCamps = () => {
  return useQuery({
    queryKey: ['camps'],
    queryFn: async () => {
      const { data } = await axios.get<CampDto[]>(environment.restApi.resourceUrl.camps);
      return data || [];
    },
    select: useCallback((response: CampDto[]) => response.map((camp) => new Camp(camp)), []),
  });
};
